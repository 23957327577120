<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="no-gutters">
        <b-col>
          <h1 class="mr-sm-4 header-tablepage">Manufacturer</h1>
        </b-col>
      </b-row>
      <div class="title-tabs mt-3">Field</div>
      <b-container class="no-gutters bg-white">
        <div class="py-3">
          <b-row>
            <b-col sm="6">
              <InputSelect
                title="Opening Days"
                name="Opening Days"
                isRequired
                class="mt-2"
                v-model.number="form.day_id"
                v-bind:options="dayList"
                valueField="value"
                textField="text"
                :v="$v.form.day_id"
                :isValidate="$v.form.day_id.$error"
              >
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >-- Select Days --</b-form-select-option
                  >
                </template>
              </InputSelect>
            </b-col>
            <div class="break-normal"></div>
            <b-col sm="6">
              <InputDatePickerFilter
                @input="
                  form.time_start > form.time_end
                    ? (form.time_end = '')
                    : (form.time_end = form.time_end)
                "
                textFloat="Opening Time"
                name="open-time"
                ref="open-time"
                format="HH:mm"
                v-model="form.time_start"
                type="time"
                :isRequired="true"
                :v="$v.form.time_start"
                :isValidate="$v.form.time_start.$error"
              />
              <!-- :maxDatetime="form.time_end" -->
            </b-col>
            <b-col sm="6">
              <InputDatePickerFilter
                @input="
                  form.time_start > form.time_end
                    ? (form.time_end = '')
                    : (form.time_end = form.time_end)
                "
                textFloat="Close Time"
                :minDatetime="form.time_start"
                name="close-time"
                ref="close-time"
                format="HH:mm"
                v-model="form.time_end"
                type="time"
                :isRequired="true"
                :v="$v.form.time_end"
                :isValidate="$v.form.time_end.$error"
              />
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="no-gutters bg-white mt-3 py-2 px-3">
        <b-form-checkbox
          switch
          class="radio-active"
          size="lg"
          :value="1"
          :unchecked-value="0"
          v-model="form.active"
        >
          <span class="ml-2 main-label">{{
            form.active ? "Active" : "Inctive"
          }}</span>
        </b-form-checkbox>
      </div>
    </div>
    <FooterAction routePath="/setting/business-hour" @submit="saveForm()" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import InputTextArea from "@/components/inputs/InputTextArea";

export default {
  name: "HourDetail",
  components: {
    OtherLoading,
    InputTextArea,
  },

  data() {
    return {
      isLoading: true,
      form: {
        day_id: "",
        time_start: "",
        time_end: "",
        active: 1,
      },
      dayList: [],
      id: this.$route.params.id,
    };
  },
  validations() {
    return {
      form: {
        day_id: { required },
        time_start: { required },
        time_end: { required },
      },
    };
  },
  async created() {
    this.dayList = this.$moment.weekdays().map((el, index) => {
      return { text: el, value: index + 1 };
    });
    await this.getDetail();
  },
  methods: {
    async getDetail() {
      this.isLoading = true;
      if (this.id > 0) {
        const res = await this.axios(`/setting/ticket/businesshour/${this.id}`);
        if (res.data.result == 1) {
          this.form = res.data.detail;
        }
      }
      this.isLoading = false;
    },
    async saveForm() {
      this.$v.$touch();
      if (!this.$v.$error) {
        let payload = { ...this.form };
        payload.time_end = this.$moment(payload.time_end).format("HH:mm");
        payload.time_start = this.$moment(payload.time_start).format("HH:mm");
        this.$bus.$emit("showLoading");
        if (this.id > 0) {
          this.isLoading = true;
          const res = await this.axios.put(
            `/setting/ticket/businesshour`,
            payload
          );
          if (res.data.result == 1) {
            this.successAlert().then(() =>
              this.$router.push("/setting/business-hour")
            );
          } else {
            this.errorAlert(res.data.message);
          }
        } else {
          this.isLoading = true;
          const res = await this.axios.post(
            `/setting/ticket/businesshour`,
            payload
          );
          if (res.data.result == 1) {
            this.successAlert().then(() =>
              this.$router.push("/setting/business-hour")
            );
          } else {
            this.errorAlert(res.data.message);
          }
        }
        this.$bus.$emit("hideLoading");
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-16 {
  font-size: 16px;
  color: var(--font-main-color);
}

.bg-gray {
  background-color: #f7f7f7;
}

.text-gray {
  color: #939393;
}

.input-container.error {
  border-color: red !important;
}

// ::v-deep .branch-modal > div {
//   margin: 0px 0px 4px 0px !important;
// }
.condition-title {
  color: var(--primary-color);
  font-size: 16px;
}

.btn-add-condition {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.panelborder {
  border: 1px solid #d8dbe0;
}

.btn-icon {
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.position-icon {
  position: absolute;
  z-index: 999;
  left: 90px;
}
</style>
